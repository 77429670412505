import React, { useState, useRef, useEffect } from 'react';
import { StyleSheet, View, Text, Clipboard, TouchableOpacity, ScrollView, TextInput, Modal } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next'
import { commonStyle } from '../components/style';
import { getRechargeTron, getRecharge, getWebsiteInfo } from '../api/apis';
import SpliteLine from '../components/spliteLine'
import Toast from 'react-native-easy-toast'
import { showAlert } from '../components/showAlert';
import AsyncStorage from '@react-native-community/async-storage';
import { unitconversion } from '../components/util'
import { AntDesign } from '@expo/vector-icons';

const Deposit = (props) => {

    const navigation = useNavigation();


    const { i18n, t } = useTranslation();
    const toastRef = useRef(null);

    const [rechargeWayBank, setRechargeWayBank] = useState(false);
    const [amount, setAmount] = useState('0.00');
    const [name, setName] = useState('');
    const [rechargeWayBankId, setRechargeWayBankId] = useState('');
    const [curr, setCurr] = useState('');

    
    React.useLayoutEffect(() => {
        navigation.setOptions({
            title: props.route.params.title,
            headerStyle: {
                backgroundColor: commonStyle.bgColor,
                borderBottomWidth: 0
            },
            headerTitleStyle: {
            },
        });
    }, [navigation]);


    useEffect(() => {
        init()
    }, []);

    const init = async () => {

        const response = await getWebsiteInfo();
        if (response.status == '1') {
            const curr = response.Data.curr
            setCurr(curr)
            getRechargeTron(curr).then(response => {
                // console.log(response)
                if (response.status == 1) {

                    if (response.item.RechargeWayBank.length == 0) {
                        navigation.navigate('PaymentMethod');
                        return
                    }

                    const index = props.route.params.itemKey
    
                    const rechargeWayBank = response.item.RechargeWayBank[index]
                    setRechargeWayBankId(rechargeWayBank.id)
                  
                    setRechargeWayBank(rechargeWayBank)

                }
            }).catch(error => {
                console.log(error)
            })

        }

    }

    const handleAmountChanged = text => {
        setAmount(text);
    };

    const handleNameChanged = text => {
        setName(text);
    };

    const handleCopy = (text) => {
        Clipboard.setString(text);
        toastRef.current.show(t('success'));
    }

    const handleConfirm = async () => {

        let websiteInfo = await AsyncStorage.getItem('websiteInfo');
        websiteInfo = JSON.parse(websiteInfo);

        if (name === '') {
            toastRef.current.show(rechargeWayBank.reconciliation_field);
            return;
        }

        // 使用正则表达式匹配金额，要求小数点后最多两位
        const regex = /^\d+(\.\d{1,2})?$/;
        if (!regex.test(amount.toString())) {
            toastRef.current.show(t('invalidAmount') + amount);
            return;
        }

        const price_min = rechargeWayBank.price_min
        const price_max = rechargeWayBank.price_max

        if (Number(amount)  < Number(rechargeWayBank.price_min) || Number(amount)  > Number(rechargeWayBank.price_max)) {
            console.log(amount)
            toastRef.current.show(t('agentReport.6') + `(${price_min}-${price_max})`);
            return;
        }

        const params = `{"Money":"${amount}","NickName":"${name}","id":"${rechargeWayBankId}"}`
        getRecharge(params, 2).then(response => {
            console.log('充值')
            // console.log(response)
            if (response.status == 1) {
                showAlert(response.msg);
                setAmount('0.00');
                setName('')
            } else {
                toastRef.current.show(response.Data.msg);
                return;
            }
        }).catch(error => {
            console.log(error)
        })
    }

    return (
        <View style={styles.container}>

            {rechargeWayBank && <View style={styles.form}>
                {/* <View>
                    <Text style={{ color: '#fff', fontSize: 20, textAlign: 'center' }}>{rechargeWayBank.bank}</Text>
                </View> */}
                {/* <View>
                    <Text style={styles.label}>{t('payee')}</Text>

                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <TextInput
                            style={styles.input}
                            editable={false}
                            underlineColorAndroid="transparent"
                            placeholderTextColor={commonStyle.green}
                            value={rechargeWayBank.payee}
                        />
                        <TouchableOpacity style={styles.copyIconContainer} onPress={() => handleCopy(rechargeWayBank.payee)}>
                            <AntDesign name="copy1" size={20} color="#828B9F" />
                        </TouchableOpacity>
                    </View>

                    <SpliteLine />
                </View> */}
                <View>
                    <Text style={styles.label}>{t('accountNumber')}</Text>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <TextInput
                            style={[styles.input,{width:'100%',height:40}]}
                            editable={false}
                            multiline={true}
                            numberOfLines={5}
                            underlineColorAndroid="transparent"
                            placeholderTextColor={commonStyle.green}
                            value={rechargeWayBank.collectionaccount}
                     
                        />
                        <TouchableOpacity style={styles.copyIconContainer} onPress={() => handleCopy(rechargeWayBank.collectionaccount)}>
                            <AntDesign name="copy1" size={20} color="#828B9F" />
                        </TouchableOpacity>
                    </View>

                    <SpliteLine />
                </View>
                <View>
                    <Text style={styles.label}>{t('bankBranch')}</Text>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <TextInput
                            style={styles.input}
                            editable={false}
                            underlineColorAndroid="transparent"
                            placeholderTextColor={commonStyle.green}
                            value={rechargeWayBank.branchname}
                        />
                        <TouchableOpacity style={styles.copyIconContainer} onPress={() => handleCopy(rechargeWayBank.branchname)}>
                            <AntDesign name="copy1" size={20} color="#828B9F" />
                        </TouchableOpacity>
                    </View>
                    <SpliteLine />
                </View>
                <View>
                    <Text style={styles.label}>{t('recharge')}{t('amount')}</Text>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <TextInput
                            style={styles.input}
                            underlineColorAndroid="transparent"
                            placeholderTextColor={commonStyle.green}
                            placeholder={'0.00'}
                            onChangeText={handleAmountChanged}
                            value={amount}
                        />
                        <TouchableOpacity style={styles.copyIconContainer} onPress={() => handleCopy(amount)}>
                            <AntDesign name="copy1" size={20} color="#828B9F" />
                        </TouchableOpacity>
                    </View>
                    <SpliteLine />
                </View>
                <View>
                    <Text style={styles.label}>{rechargeWayBank.reconciliation_field}</Text>
                    <TextInput
                        style={styles.input}
                        underlineColorAndroid="transparent"
                        placeholderTextColor={commonStyle.green}
                        onChangeText={handleNameChanged}
                        placeholder={rechargeWayBank.reconciliation_field}
                        value={name}
                    />
                    <SpliteLine />
                </View>
                <TouchableOpacity style={styles.button} onPress={handleConfirm}>
                    <Text style={styles.buttonText}>{t('confirm')}</Text>
                </TouchableOpacity>

                <View style={{ marginTop: 20 }}>
                    <Text style={{ color: 'gray' }}>{t('tips')}</Text>
                    <Text style={{ color: 'gray' }}>{t('rechargeTip.4')}</Text>
                </View>

            </View>}

            <Toast
                ref={toastRef}
                style={{ backgroundColor: 'gray' }}
                position='top'
                positionValue={200}
                // fadeInDuration={750}
                // fadeOutDuration={1000}
                // opacity={0.8}
                textStyle={{ color: '#fff' }}
            />
        </View>

    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,  // 主轴上尺寸比例
        backgroundColor: commonStyle.bgColor,
        alignItems: 'center', // 交叉轴对齐方式
        justifyContent: 'center',//主轴对齐方式
        // flexDirection:"column" //主轴方向
    },
    form: {
        width: '80%',
    },
    label: {
        color: '#fff',
        fontSize: 16,
        marginTop: 10,
    },
    input: {
        color: commonStyle.green,
        marginBottom: 5,
        marginTop: 5,
        height: 30,
        borderWidth: 0,
        borderBottomColor: 'transparent',
    },
    inviteCodeInput: {
        flex: 2,
        width: 230,
    },
    inputContainer: {
        flexDirection: 'row',
    },
    requiredText: {
        flex: 1,
        color: '#fff',
        lineHeight: 45,
        textAlign: 'right',
        fontSize: 18,
    },
    button: {
        backgroundColor: commonStyle.green,
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
        marginTop: 10,
        marginBottom: 10,
        borderRadius: 5,
    },
    buttonText: {
        color: '#fff',
        fontWeight: 'bold',
        width: '100%',
        textAlign: 'center',
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
        maxWidth: 500,
        width: '100%',
        marginHorizontal: 'auto'
        // backgroundColor: 'rgba(0,0,0,0.5)',
    },
    modalContent: {
        backgroundColor: '#1B1D29',
        // padding: 20,
        borderRadius: 10,
        width: '100%',
        height: '80%'
    },
    modelDown: {
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 'auto',
        marginBottom: 15,
        marginTop: 20
    },
    modalText: {
        color: '#8C99B3',
        textAlign: 'center',
        height: 40,
        fontSize: 16,
        lineHeight: 35
    },
    modalTextSelected: {
        backgroundColor: '#0F1017',
        opacity: 0.45
    },
    iconText: {
        flexDirection: 'row',
        alignItems: 'center',
        // marginBottom: 10,
        color: '#828B9F',
    },
    goArrow: {
        fontSize: 20,
        // fontWeight: 'bold',
    },
    card: {
        backgroundColor: '#FAAE27',
        borderRadius: 5,
        justifyContent: 'center',
        // paddingVertical: 15,
        // paddingHorizontal: 10,
        marginTop: 10,
    },
});

export default Deposit;