import { Platform, Alert } from 'react-native';

export function showAlert(message, onConfirm) {
  if (Platform.OS === 'web') {
    if (window.confirm(message) && typeof onConfirm === 'function') {
      onConfirm();
    }
  } else {
    const buttons = [
      { text: '', style: 'cancel' },
    ];

    if (typeof onConfirm === 'function') {
      buttons.push({ text: '确定', onPress: onConfirm });
    }

    Alert.alert('', message, buttons, { cancelable: false });
  }
}
