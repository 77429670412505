import React, { useState, useEffect, useRef } from 'react';
import { View, Text, Image, TouchableOpacity, FlatList, ActivityIndicator, StyleSheet, RefreshControl, Platform, Dimensions, Clipboard } from 'react-native';
import ActiveTabItem from '../components/activeTabItem'
import { commonStyle } from '../components/style'
import { useTranslation } from 'react-i18next';
import { AntDesign } from '@expo/vector-icons';
import { getyuebao, betcenterMine } from '../api/apis';
import { unitconversion, formatTimestampToDateTime, timeConvers } from '../components/util'
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import Toast, { DURATION } from 'react-native-easy-toast'
import ProgressBar from 'react-native-progress-bar-animated';

const App = () => {

    if (Dimensions.get('window').width > 500) {
        Dimensions.get('window').width = 500
    }

    const { width } = Dimensions.get('window');

    const { t } = useTranslation();
    const toastRef = useRef(null);
    const navigation = useNavigation();

    const [systime, setSystime] = useState(0);
    const [data, setData] = useState([]);
    const [refreshing, setRefreshing] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [emptyShow, setEmptyShow] = useState(false);
    const [tabs, setTabs] = useState(1);
    const [page, setPage] = useState(1);

    useEffect(() => {
        // 初始化数据

        handleRefresh()

    }, []);


    const handleRefresh = () => {
        setRefreshing(true)
        setPage(1)
        // setData([])

        setTabs((currentTabs) => {
            record(1, currentTabs);
            return currentTabs;
        });
    };

    // 加载更多数据
    const handleLoadMore = () => {
        setLoadingMore(true);
        const nextPage = page + 1
        setPage(nextPage)
        setTabs((currentTabs) => { // 使用函数形式的 setTabs
            record(nextPage, currentTabs);
            return currentTabs;
        });

    };

    const tab = (value) => {
        setTabs(value)
        setEmptyShow(false)


        handleRefresh()
    }

    //获取记录
    const record = async (currentPage, currentTabs) => {

        await getyuebao('mining').then(async response => {
            if (response.status == 1) {

                const mining = response.Data.reduce((acc, currency) => {
                    currency.data.forEach(item => {
                        acc[item.id] = currency.Currency;
                    });
                    return acc;
                }, {});

                await betcenterMine(currentPage, currentTabs).then(response => {
                    setRefreshing(false);
                    setLoadingMore(false);

                    if (response.status == 1) {

                        const systime = response.Data1.systime

                        if ("" == response.Data1.item) {
                            response.Data1.item = []
                        }

                        const updateData = response.Data1.item.map(data => {

                            const timeDiff = Math.abs(Number(data.end_time) - Number(data.start_time));
                            const daysDiff = Math.ceil(timeDiff / (60 * 60 * 24));
                            data.cycle = daysDiff

                            if (systime > Number(data.end_time)) {
                                data.progress = 0
                            } else {
                                data.progress = (Number(data.end_time) - Number(systime)) / (Number(data.end_time) - Number(data.start_time)) * 100
                            }


                            const start_time = timeConvers(Number(data.start_time) * 1000);
                            data.start_time = `${start_time.Y}/${start_time.M}/${start_time.D} ${start_time.h}:${start_time.min}`;

                            const end_time = timeConvers(Number(data.end_time) * 1000);
                            // console.log(data.end_time);
                            // console.log(end_time);
                            data.end_time = `${end_time.Y}/${end_time.M}/${end_time.D} ${end_time.h}:${end_time.min}`;


                            if (data.status == 1) {
                                data.status = t('unfinished')
                            } else if (data.status == 2) {
                                data.status = t('end')
                            } else {
                                data.status = ''
                            }

                            data.Currency = mining[data.EarnWalletTimeid]
                            return data
                        });

                        const dataList = data.concat(updateData)
                        if (currentPage > 1) {
                            setData(dataList)
                        } else {
                            setData(updateData)
                            // console.log('datalist')
                            // console.log(dataList)
                            if (dataList.length == 0 || "" == response.Data1.item) {
                                setEmptyShow(true)
                            }
                        }


                    }
                }).catch(error => {
                    setRefreshing(false);
                    setLoadingMore(false);
                    console.error(error);
                });
            }
        });



    };




    const handleCopy = (value) => {
        Clipboard.setString(value);
        toastRef.current.show(t('success'));
    }

    const renderItem = ({ item }) => (
        <><View style={styles.itemContainer}>
            <TouchableOpacity>
                <View style={styles.itemRowContainer}>
                    <View><Text style={styles.itemTextGreen}>{item.Currency} </Text></View>
                    <View><Text style={styles.itemTextGray}>{t('amount')}：{unitconversion(item.Amount)} </Text></View>
                </View>
                <View style={styles.itemRowContainer}>
                    <View><Text style={styles.itemTextGray}>{t('interest')}：{item.Interest}%</Text></View>
                    <View><Text style={unitconversion(Math.floor(item.Interestreceived)) == '0.00' ? styles.itemTextGray : styles.itemTextGreen}>{t('received')}：{unitconversion(Math.floor(item.Interestreceived))}</Text></View>
                </View>
                <View style={styles.itemRowContainer}>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.itemTextGray}>{item.order_number}</Text>
                        <TouchableOpacity style={{ marginTop: 2, marginLeft: 2 }} onPress={() => handleCopy(item.order_number)}>
                            <AntDesign name="copy1" size={15} color="#828B9F" />
                        </TouchableOpacity>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        {/* <Text style={styles.itemTextGray}> {t('copyOrderNumber')}</Text> */}

                    </View>
                </View>
                <View style={styles.itemRowContainer}>
                    <View><Text style={styles.itemTextGray}>{item.start_time} - {item.end_time}</Text></View>
                </View>
                <View style={styles.itemRowContainer}>
                    <View><Text style={styles.itemTextGray}>{t('cycle')}: {item.cycle}{t('day')}</Text></View>
                </View>
            </TouchableOpacity>
        </View>
            <ProgressBar
                width={width}
                height={2}
                borderRadius={5}
                borderWidth={0}
                maxValue={100}
                value={item.progress}
                color="#6CC644"
                backgroundColorOnComplete="#6CC644"
                backgroundColor={commonStyle.green} /></>
    );

    return (
        <View style={styles.container}>

            {/* <Image style={styles.image} source={require('../../assets/imgs/common/empty.png')} /> */}

            <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginBottom: 10, marginTop: 5 }} >
                <TouchableOpacity style={{ flex: 1 }} onPress={() => tab(1)}>
                    <View >
                        <Text style={{ color: '#fff', fontSize: 16, textAlign: 'center' }}>{t('unfinished')}</Text>
                        {tabs == 1 && <View style={{ height: 2, marginHorizontal: 20, backgroundColor: commonStyle.green, marginTop: 5 }}></View>}
                    </View>
                </TouchableOpacity>
                <TouchableOpacity style={{ flex: 1 }} onPress={() => tab(2)}>
                    <View >
                        <Text style={{ color: '#fff', fontSize: 16, textAlign: 'center' }} >{t('end')}</Text>
                        {tabs == 2 && <View style={{ height: 2, marginHorizontal: 20, backgroundColor: commonStyle.green, marginTop: 5 }}></View>}
                    </View>
                </TouchableOpacity>
            </View>
            {emptyShow && <View style={{ justifyContent: 'center', alignItems: 'center', height: '80%' }}>
                <Text style={{ color: '#fff' }}>{t('noData')}</Text>
            </View>}

            {Platform.OS == 'web' && refreshing && !loadingMore && <ActivityIndicator color={commonStyle.green} />}

            <FlatList
                data={data}
                renderItem={renderItem}
                keyExtractor={(item, index) => item.order_number}
                refreshControl={<RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />}
                onEndReached={!loadingMore && handleLoadMore}
                onEndReachedThreshold={0.1}
                ListFooterComponent={loadingMore && <ActivityIndicator color={commonStyle.green} />}
            />
            <Toast
                ref={toastRef}
                style={{ backgroundColor: 'gray' }}
                position='top'
                positionValue={300}
                textStyle={{ color: '#fff' }}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor
    },
    tabContainer: {
        flexDirection: 'row',
        paddingVertical: 10,
        backgroundColor: '#1B1D29',
    },
    tabItem: {
        flex: 1,
        alignItems: 'center',
        paddingVertical: 10,
    },
    tabText: {
        color: commonStyle.textGreenColor,
    },
    itemTextGreen: {
        color: commonStyle.textGreenColor,
    },
    itemTextRed: {
        color: commonStyle.textRedColor,
    },
    itemTextGray: {
        color: commonStyle.textGrayColor
    },
    itemContainer: {
        flexDirection: "column",
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderBottomWidth: 1,
        backgroundColor: '#1B1D29',
        marginTop: 5,
    },
    itemRowContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 4
    },
    activeTabItem: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    image: {

    }

});

export default App;