import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Text, TextInput, TouchableOpacity, Image, FlatList } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { commonStyle } from '../components/style';
import { Feather, MaterialIcons } from '@expo/vector-icons';
import { getUserbalance } from '../api/apis';
import { unitconversion } from '../components/util'
import AsyncStorage from '@react-native-community/async-storage';

const Wallet = () => {
    const navigation = useNavigation();
    const { i18n, t } = useTranslation();

    const data = [
        // { name: 'USDT' },
    ];

    const [eye, setEye] = useState('off');
    const [searchText, setSearchText] = useState('');
    const [filteredData, setFilteredData] = useState(data);
    const [balance, setBalance] = useState('');
    const [currency, setCurrency] = useState('USD');

    useEffect(() => {
        userbalance()
    }, []);


    const handleEye = (index) => {
        console.log(index);
        if (index === 'on') {
            setEye('off');
        } else {
            setEye('on');
        }
    };

    const handleSearch = (text) => {
        console.log(text)
        setSearchText(text);

        // 过滤数据
        const filtered = data.filter((item) =>
            item.name.toLowerCase().includes(text.toLowerCase())
        );
        setFilteredData(filtered);
    };


    // 获取余额
    const userbalance = async () => {
        getUserbalance().then(response => {
            if (response.status == 1 && response.Data.Code == '1') {
                setBalance(response.Data.BackData);
            }
        }).catch(error => {
            // console.error(error);
        });

        const l = await AsyncStorage.getItem('websiteInfo');
        const lj = JSON.parse(l);
        if (lj.Data.curr != '') {
            setCurrency(lj.Data.curr)
        }

    };

    const goToRechargeRecordScreen = () => {
        navigation.navigate('RechargeRecord');
    };

    const goToWithdrawalRecordScreen = () => {
        navigation.navigate('WithdrawalRecords');
    };

    const goToContractRecordsScreen = () => {
        navigation.navigate('BillingRecord');
    }

    const goToBettingScreen = () => {
        // navigation.navigate('ContractRecords');
        navigation.navigate('BetRecord');
    }

    return (
        <View style={styles.container}>
            <View style={styles.contentContainer}>
                <View style={styles.accountInfoContainer}>
                    <View style={styles.accountInfoHeader}>
                        <Text style={styles.totalAssetsText}>{t('totalAssets')}</Text>
                        <View style={styles.eyeIconContainer}>
                            {eye === 'on' && (
                                <TouchableOpacity onPress={() => handleEye('on')}>
                                    <Feather name="eye" size={20} color="#707070" />
                                </TouchableOpacity>
                            )}
                            {eye === 'off' && (
                                <TouchableOpacity onPress={() => handleEye('off')}>
                                    <Feather name="eye-off" size={20} color="#707070" />
                                </TouchableOpacity>
                            )}
                        </View>
                    </View>
                    <View>
                        <Text style={styles.usdtAmountText}>
                            {eye === 'on' ? unitconversion(balance) : '***** '}
                        </Text>
                    </View>
                    <View>
                        {/* <Text style={styles.cnyAmountText}>
                            = {eye === 'on' ? '9.999999 CNY' : '***** CNY'}
                        </Text> */}
                    </View>
                </View>

                <View style={styles.section}>
                    <View style={styles.iconText}>
                        <TouchableOpacity style={styles.icon} onPress={goToBettingScreen}>
                            <Image style={styles.image} source={require('../../assets/imgs/wallet/bill.png')} />
                        </TouchableOpacity>
                        <Text style={styles.labelTop}>{t('betting')}</Text>
                    </View>

                    <View style={styles.iconText}>
                        <TouchableOpacity style={styles.icon} onPress={goToContractRecordsScreen}>
                            <Image style={styles.image} source={require('../../assets/imgs/wallet/bill.png')} />
                        </TouchableOpacity>
                        <Text style={styles.labelTop}>{t('bill')}</Text>
                    </View>

                    <View style={styles.iconText}>
                        <TouchableOpacity style={styles.icon} onPress={() => goToRechargeRecordScreen()}>
                            <Image style={styles.image} source={require('../../assets/imgs/wallet/deposit.png')} />
                        </TouchableOpacity>
                        <Text style={styles.labelTop}>{t('recharge')}</Text>
                    </View>
                    <View style={styles.iconText}>
                        <TouchableOpacity style={styles.icon} onPress={() => goToWithdrawalRecordScreen()}>
                            <Image style={styles.image} source={require('../../assets/imgs/wallet/extractCurrency.png')} />
                        </TouchableOpacity>
                        <Text style={styles.labelTop}>{t('withdrawal')}</Text>
                    </View>
                </View>

                {/* <View style={{ flexDirection: 'row', alignItems: 'center', backgroundColor: '#2C3445', width: 150, marginBottom: 10 }}>
                    <MaterialIcons name="search" size={20} color="#fff" />
                    <TextInput
                        style={styles.input}
                        placeholder={t('searchCurrency')}
                        onChangeText={handleSearch}
                        underlineColorAndroid="transparent"
                        placeholderTextColor="#707070"
                        maxLength={10}
                        value={searchText}
                    />
                </View> */}

                <View style={styles.separator}></View>

                <View style={{ backgroundColor: '#1B1D29', paddingBottom: 10 }}>
                    <View style={{ flexDirection: 'column' }}>
                        <FlatList
                            data={filteredData}
                            renderItem={({ item, index }) => (
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 5 }}>
                                    <View style={{ flex: 1 }}>
                                        <Image style={styles.image} source={require('../../assets/imgs/wallet/btc.png')} />
                                        <Text></Text>
                                        <Text></Text>
                                        <Text></Text>
                                    </View>
                                    <View style={{ flex: 3 }}>
                                        <Text style={styles.walletItemTitle}>{item.name}</Text>
                                        <Text style={{ color: commonStyle.textWhiteColor }}>持有</Text>
                                        <Text style={styles.walletItemText}>91</Text>
                                        {/* <Text style={{ color: commonStyle.textWhiteColor }}>=999 CNY</Text> */}
                                    </View>
                                    <View style={{ flex: 3 }}>
                                        <Text style={styles.walletItemTitle}> </Text>
                                        <Text style={{ color: commonStyle.textWhiteColor }}>可用</Text>
                                        <Text style={styles.walletItemText}>92</Text>
                                        <Text style={{ color: commonStyle.textWhiteColor }}></Text>
                                    </View>
                                    <View style={{ flex: 3 }}>
                                        <Text style={styles.walletItemTitle}> </Text>
                                        <Text style={{ color: commonStyle.textWhiteColor }}>锁定</Text>
                                        <Text style={styles.walletItemText}>93</Text>
                                        <Text> </Text>
                                    </View>
                                </View>

                            )}
                            keyExtractor={(index) => index}
                        />
                    </View>
                </View>

            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor,
        alignItems: 'center',
    },
    contentContainer: {
        width: '95%',
        marginTop: 15
    },
    accountInfoContainer: {
        backgroundColor: '#2C3445',
        borderRadius: 5,
        flexDirection: 'column',
        padding: 10,
    },
    accountInfoHeader: {
        flexDirection: 'row',
    },
    totalAssetsText: {
        color: '#fff',
    },
    eyeIconContainer: {
        marginLeft: 5,
    },
    usdtAmountText: {
        color: '#13B777',
        fontSize: 25,
    },
    cnyAmountText: {
        color: '#13B777',
    },
    section: {
        marginVertical: 20,
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    iconText: {
        flexDirection: 'colum',
        alignItems: 'center',
        color: '#828B9F',
    },
    icon: {},
    labelTop: {
        color: commonStyle.textWhiteColor,
        marginTop: 2,
    },
    image: {
        width: 30,
        height: 30,
    },
    separator: {
        height: 1,
        backgroundColor: '#fff',
    },
    input: {
        borderWidth: 0,
        borderBottomColor: 'transparent',
        color: '#fff',
        borderRadius: 2,
        padding: 5,
        width: 130,
    },
    walletItemTitle: {
        fontSize: 18,
        color: '#fff',
        marginTop: 2
    },
    walletItemText: {
        color: '#fff',
        fontSize: 16,
    }
});

export default Wallet;